import { Company } from '../types';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Stepper } from '@xtreamsrl/react-ui-kit/Stepper';
import { Step } from '@xtreamsrl/react-ui-kit/Step';
import { config } from '@xtreamsrl/react-config';
import { CassettoApiGreyIcon } from '@cassetto/icons';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';

const getSteps = () => {
  const steps = [
    {
      text: 'Termini e condizioni',
      details:
        'Leggi e accetta la nostra privacy policy per garantire la protezione dei tuoi dati.',
    },
    {
      text: 'Accesso e Recupero Documenti',
      details:
        'Accedi a una o più piattaforme da cui recuperare i tuoi documenti necessari per procedere.',
    },
  ];

  if (config.isDemo)
    return [
      ...steps,
      {
        text: 'Lista Documenti',
        details: `Una volta recuperati i documenti, visualizza la lista dei documenti ottenuti e, quando possibile, scarica localmente i file`,
      },
    ];

  return steps;
};

export function SidePanel(props: { requestFrom: Company; activeStep: number }) {
  return (
    <Flex
      direction="column"
      gap="sm-6"
      flex={1}
      borderRight="1px solid #F1F3F5"
    >
      <Box paddingX="md-4" paddingTop="md-7" paddingBottom="0">
        <Flex gap="sm-2" alignItems="center" marginBottom="sm-2">
          <CassettoApiGreyIcon />
          <Typography variant="body/base/medium" color="grey.9">
            per
          </Typography>
        </Flex>
        <img
          alt={`Logo ${props.requestFrom.name}`}
          style={{
            height: '40px',
            objectFit: 'contain',
          }}
          src={props.requestFrom.logo}
        />
      </Box>
      <Flex
        direction="column"
        paddingX="md-4"
        paddingTop="xl-2"
        paddingBottom="sm-6"
        gap="sm-2"
      >
        <Stepper orientation="vertical" activeStep={props.activeStep}>
          {getSteps().map((step, index) => (
            <Step key={index} label={step.text} caption={step.details} />
          ))}
        </Stepper>
      </Flex>
    </Flex>
  );
}
