import './_shared/config';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ServerStateManagementProvider } from '@xtreamsrl/react-query';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@xtreamsrl/react-ui-kit/theme';
import { createCustomTheme } from './theme';
import { configureTranslation, I18nProvider } from '@xtreamsrl/react-i18n';
import { translations } from './_shared/i18n';
import { PostHogProvider } from 'posthog-js/react';
import { config } from '@xtreamsrl/react-config';

configureTranslation(translations);

const posthogOptions = {
  api_host: config.posthogHost,
};

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <PostHogProvider apiKey={config.posthogKey} options={posthogOptions}>
    <React.StrictMode>
      <I18nProvider locale="it" changeLocale={() => {}}>
        <ThemeProvider theme={createCustomTheme()}>
          <ServerStateManagementProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ServerStateManagementProvider>
        </ThemeProvider>
      </I18nProvider>
    </React.StrictMode>
  </PostHogProvider>,
);
