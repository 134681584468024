import { createQuery } from '@xtreamsrl/react-query';
import { getFile } from '../apis/getFile';

const getFileQuery = createQuery(
  ({ token, documentId }: { token: string; documentId: string }) => ({
    queryKey: ['file', token, documentId],
    queryFn: () => getFile(token, documentId),
  }),
);

export const fetchFile = ({
  token,
  documentId,
}: {
  token: string;
  documentId: string;
}) => getFileQuery.fetch({ token, documentId });
