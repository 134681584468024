import { config } from '@xtreamsrl/react-config';

export async function acceptTerms(requestId: string): Promise<void> {
  return await fetch(
    `${config.baseUrl}/documents-requests/${requestId}/acceptTerms`,
    {
      method: 'POST',
    },
  ).then((response) => {
    if (response.ok) return;
    throw new Error('Failed to accept terms');
  });
}
