import React from 'react';
import { TwoFactorAuth, TwoFactorHandling } from '../../../types';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { useTwoStepSelectionEventsHandler } from '../../../hooks/useTwoStepSelectionEventsHandler';
import { OtpGeneratorOption } from '../OtpGeneratorOption';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { TwoFactorAuthenticationOption } from '../TwoFactorAuthenticationOption';
import { NotificationIcon, SmsIcon } from '@cassetto/icons';
import { Spinner } from '@xtreamsrl/react-ui-kit/Spinner';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { RoundedBox } from '../../BoxVariants';

interface TwoFactorSelectionStepProps {
  twoFactorHandling: TwoFactorHandling;
}

const TwoFactorSelectionStep: React.FC<TwoFactorSelectionStepProps> = ({
  twoFactorHandling,
}) => {
  const {
    nameOfTwoFactorInLoadingState,
    nameOfSelectedTwoFactor,
    setNameOfSelectedTwoFactor,
    selectedOtpGenerator,
    setSelectedOtpGenerator,
    handleChosenTwoFactorAuthMethod,
  } = useTwoStepSelectionEventsHandler({
    automaticallySelected: twoFactorHandling.automaticallySelected,
  });

  const availableTwoFactors = Object.keys(twoFactorHandling.twoFactors).filter(
    (e) => twoFactorHandling.twoFactors[e as TwoFactorAuth].available,
  );
  const otpGeneratorIds =
    twoFactorHandling.twoFactors[TwoFactorAuth.OTP].options?.otpGeneratorIds;

  return (
    <>
      {nameOfTwoFactorInLoadingState ? (
        <Flex direction="column" flex={1} alignItems="center" gap="md-1">
          {nameOfTwoFactorInLoadingState === TwoFactorAuth.NOTIFICATION && (
            <>
              <NotificationIcon />
              <Typography variant="body/base/semibold" textAlign="center">
                Notifica push
              </Typography>
              <Typography
                variant="body/xs/regular"
                textAlign="center"
                color="grey.11"
              >
                Abbiamo inviato una notifica sul tuo dispositivo.
                <br />
                Approva la richiesta di accesso direttamente dal tuo dispositivo
                per completare l'autenticazione.
              </Typography>
            </>
          )}
          {nameOfTwoFactorInLoadingState === TwoFactorAuth.SMS && (
            <>
              <SmsIcon />
              <Typography variant="body/base/semibold" textAlign="center">
                SMS
              </Typography>
              <Typography
                variant="body/xs/regular"
                textAlign="center"
                color="grey.11"
              >
                Stiamo inviando un SMS sul tuo dispositivo.
                <br />
                Attendi per inserire il codice e completare il login.
              </Typography>
            </>
          )}
          {nameOfTwoFactorInLoadingState !== TwoFactorAuth.NOTIFICATION &&
            nameOfTwoFactorInLoadingState !== TwoFactorAuth.SMS && (
              <>
                <Spinner state="indeterminate" />
                <Typography variant="body/base/semibold" textAlign="center">
                  Caricamento in corso...
                </Typography>
              </>
            )}
        </Flex>
      ) : (
        <>
          <Flex direction="column" flex={1}>
            {availableTwoFactors.includes(TwoFactorAuth.NOTIFICATION) && (
              <RoundedBox
                index={availableTwoFactors.indexOf(TwoFactorAuth.NOTIFICATION)}
                total={availableTwoFactors.length}
              >
                <TwoFactorAuthenticationOption
                  title="Notifica push"
                  description="Ricevi una notifica sul tuo dispositivo. Approva la richiesta di accesso direttamente dal tuo dispositivo per completare l'autenticazione in modo semplice e sicuro"
                  name={TwoFactorAuth.NOTIFICATION}
                  selected={nameOfSelectedTwoFactor}
                  setSelected={setNameOfSelectedTwoFactor}
                />
              </RoundedBox>
            )}
            {availableTwoFactors.includes(TwoFactorAuth.SMS) && (
              <RoundedBox
                index={availableTwoFactors.indexOf(TwoFactorAuth.SMS)}
                total={availableTwoFactors.length}
              >
                <TwoFactorAuthenticationOption
                  title="SMS"
                  description="Ricevi un codice OTP (One-Time Password) via SMS al numero di telefono associato al tuo account, inserisci il codice e proteggi il tuo account"
                  name={TwoFactorAuth.SMS}
                  selected={nameOfSelectedTwoFactor}
                  setSelected={setNameOfSelectedTwoFactor}
                />
              </RoundedBox>
            )}
            {availableTwoFactors.includes(TwoFactorAuth.OTP) &&
              (otpGeneratorIds ? (
                otpGeneratorIds.map((otpGeneratorId, index) => (
                  <RoundedBox index={index} total={otpGeneratorIds.length}>
                    <OtpGeneratorOption
                      key={otpGeneratorId}
                      title="OTP"
                      description="Recupera il codice tramite l’app, inserisci il codice e proteggi il tuo account"
                      selected={nameOfSelectedTwoFactor}
                      setSelected={setNameOfSelectedTwoFactor}
                      otpGeneratorId={otpGeneratorId}
                      selectedOtpGenerator={selectedOtpGenerator}
                      setSelectedOtpGenerator={setSelectedOtpGenerator}
                    />
                  </RoundedBox>
                ))
              ) : (
                <RoundedBox
                  index={availableTwoFactors.indexOf(TwoFactorAuth.OTP)}
                  total={availableTwoFactors.length}
                >
                  <TwoFactorAuthenticationOption
                    title="OTP"
                    description="Recupera il codice tramite l’app, inserisci il codice e proteggi il tuo account"
                    name={TwoFactorAuth.OTP}
                    selected={nameOfSelectedTwoFactor}
                    setSelected={setNameOfSelectedTwoFactor}
                  />
                </RoundedBox>
              ))}
          </Flex>
          <Box alignSelf="center">
            <Button
              onClick={handleChosenTwoFactorAuthMethod()}
              variant="outlined"
              loading={nameOfTwoFactorInLoadingState}
              color="grey"
              style={{ color: 'black' }}
            >
              Conferma
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

TwoFactorSelectionStep.displayName = 'TwoFactorSelectionStep';
export default TwoFactorSelectionStep;
