import { createMutation } from '@xtreamsrl/react-query';
import { createDemoRequest } from '../apis/createDemoRequest';

const createDemoRequestMutation = createMutation({
  mutatingFunction: createDemoRequest,
  options: {
    onSuccess: (data) => {
      console.log('Demo request created with id:', data);
    },
  },
});

export const useCreateDemoRequest = createDemoRequestMutation.hook;
