import React from 'react';
import {
  Company,
  Document,
  DownloadReport,
  Platforms,
  platformStatus,
  PlatformStatus,
  platformToDisplayName,
  platformToLogo,
} from '../types';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { Accordion } from '@xtreamsrl/react-ui-kit/Accordion';
import {
  isInFinalStatus,
  toMoreDescriptiveName,
} from '../../_shared/utils/utils';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { DocumentFilterDisplay } from './DocumentFilterDisplay';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Badge } from './Badge';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { ErrorIcon, FileIcon, SuccessIcon, WarningIcon } from '@cassetto/icons';
import { Spinner } from '@xtreamsrl/react-ui-kit/Spinner';
import { isStatePotentiallyChangingServerSide } from '../utils';
import { RoundedBox } from './BoxVariants';
import Callout from './Callout/Callout';

export const PlatformWithDocsRecap: React.FC<{
  platform: Platforms;
  requestFrom: Company;
  status: PlatformStatus;
  documents: Document[];
  downloadReport: DownloadReport;
  setActivePlatform: (platform: Platforms) => void;
  hasJustBeenAccessed: boolean;
  open: boolean;
}> = ({
  platform,
  requestFrom,
  documents,
  status,
  downloadReport,
  setActivePlatform,
  hasJustBeenAccessed,
  open,
}) => {
  return (
    <Accordion defaultExpanded={open}>
      {/* todo: make background grey when showing details */}
      <Accordion.Summary>
        <Flex gap="sm-8">
          <img
            src={platformToLogo[platform]}
            alt={`Logo ${platformToDisplayName[platform]}`}
            style={{
              height: '44px',
            }}
          />
          <Flex direction="column">
            <Flex gap="sm-4" alignItems="center">
              <Typography variant="body/base/semibold">
                {platformToDisplayName[platform]}
              </Typography>
              {(status === platformStatus.DOWNLOADING ||
                (hasJustBeenAccessed &&
                  isStatePotentiallyChangingServerSide(status))) && (
                <Badge state="loading" />
              )}
              {status === platformStatus.DOWNLOAD_FAILED && (
                <Badge state="error" />
              )}
              {status === platformStatus.DOWNLOAD_COMPLETED && (
                <Badge state="success" />
              )}
              {status === platformStatus.DOWNLOAD_PARTIAL && (
                <Badge state="warning" />
              )}
            </Flex>
            <Typography variant="body/sm/regular" color="grey.11">
              {documents
                .map((doc) => toMoreDescriptiveName(doc.type))
                .join(', ')}
            </Typography>
          </Flex>
        </Flex>
      </Accordion.Summary>
      <Accordion.Details>
        <Flex
          direction="column"
          paddingTop="0"
          paddingRight="lg-2"
          paddingBottom="sm-8"
          paddingLeft="lg-2"
          gap="sm-5"
        >
          <Flex direction="column">
            {documents.map((doc, index) => {
              const docStatus =
                downloadReport &&
                downloadReport.find((item) => item.type === doc.type)
                  ?.documentTypeDownloadStatus.status;

              return (
                <RoundedBox
                  key={doc.type}
                  index={index}
                  total={documents.length}
                  paddingY="sm-4"
                  paddingX="sm-8"
                >
                  <Flex gap="sm-4" alignItems="flex-start">
                    <FileIcon />
                    <Flex
                      flex={1}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Flex gap="sm-6" direction="column">
                        <Typography variant="body/base/semibold">
                          {toMoreDescriptiveName(doc.type)}
                        </Typography>
                        <DocumentFilterDisplay document={doc} />
                      </Flex>
                      {docStatus === 'COMPLETED' && (
                        <Icon>
                          <SuccessIcon />
                        </Icon>
                      )}
                      {docStatus === 'ERROR' && (
                        <Icon>
                          <ErrorIcon />
                        </Icon>
                      )}
                      {docStatus === 'PARTIAL' && (
                        <Icon>
                          <WarningIcon />
                        </Icon>
                      )}
                      {!docStatus &&
                        (status === platformStatus.DOWNLOADING ||
                          hasJustBeenAccessed) && (
                          <Spinner state="indeterminate" size={20} />
                        )}
                    </Flex>
                  </Flex>
                </RoundedBox>
              );
            })}
          </Flex>
          {!isInFinalStatus(status) &&
            status !== platformStatus.DOWNLOADING &&
            !hasJustBeenAccessed && (
              <Box alignSelf="flex-end">
                <Button
                  variant="outlined"
                  style={{ textTransform: 'none', color: 'grey.12' }}
                  color="grey"
                  size="lg"
                  onClick={() => setActivePlatform(platform)}
                >
                  {`Accedi ad ${platformToDisplayName[platform]}`}
                </Button>
              </Box>
            )}
          {isInFinalStatus(status) &&
            status !== platformStatus.DOWNLOAD_COMPLETED && (
              <Callout
                status={
                  status === platformStatus.DOWNLOAD_PARTIAL
                    ? 'warning'
                    : 'error'
                }
                text="Errore nel recupero dei documenti"
              >
                <Typography variant="body/sm/regular">
                  Non è stato possibile recuperare tutti i documenti richiesti.
                  Tuttavia,{' '}
                  <b>
                    la procedura potrà proseguire anche con la documentazione
                    parziale.
                  </b>{' '}
                  {requestFrom.name} ti contatterà in caso fosse necessario
                  fornire ulteriori informazioni.
                </Typography>
              </Callout>
            )}
        </Flex>
      </Accordion.Details>
    </Accordion>
  );
};
