import {
  AuthenticationData,
  C2SEvent,
  LoginStep,
  S2CEvent,
  TwoFactorAuth,
  TwoFactorHandling,
} from '../types';
import { useContext, useEffect, useState } from 'react';
import { useForm } from '@xtreamsrl/react-forms';
import * as yup from 'yup';
import { LoginContext } from './useLogin';

interface AuthenticationEventsHandlerProps {
  setTwoFactorHandling: (twoFactors: TwoFactorHandling) => void;
}

export function useAuthenticationEventsHandler({
  setTwoFactorHandling,
}: AuthenticationEventsHandlerProps) {
  const loginContext = useContext(LoginContext);
  if (!loginContext || !loginContext.socket)
    throw new Error(
      'useAuthenticationEventsHandler must be used within a LoginContext and socket cannot be null.',
    );

  const { socket, setStep } = loginContext;
  const [qrImageSrc, setQrImageSrc] = useState<string | null>(null);
  const [isQrSrcValid, setIsQrSrcValid] = useState<boolean>(true);
  const [loadingPhase, setLoadingPhase] = useState<
    'init' | 'verifyingCredentials' | undefined
  >('init');
  const [progress, setProgress] = useState(0);

  useEffect(function fakeLoginMethodsProgressBar() {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 95) {
          clearInterval(interval);
          return 95;
        }
        return prevProgress + 1;
      });
    }, 150);

    return () => clearInterval(interval);
  }, []);

  const authForm = useForm({
    initialValues: { username: '', password: '' },
    validationSchema: yup.object().shape({
      username: yup.string().required('errors.required'),
      password: yup.string().required('errors.required'),
    }),
    mode: 'onBlur',
  });

  const handleAuthentication = (credentials: AuthenticationData) => {
    setLoadingPhase('verifyingCredentials');

    // Send login credentials to the server
    socket.emit(C2SEvent.CREDENTIALS, {
      ...credentials,
    });
  };

  const submit = authForm.formProps.handleSubmit((values, event) => {
    event?.preventDefault();
    handleAuthentication(values);
  });

  useEffect(() => {
    // Listen for server responses
    socket.on(S2CEvent.SESSION_STARTED, (data: { qrImage: string | null }) => {
      // qrImage contains the src of the QR code, or undefined if the SPID provider does not support it
      setQrImageSrc(data.qrImage);
      console.log(
        `Initialisation completed, provider ${data.qrImage ? 'supports' : 'does not support'} QR code`,
      );
      setProgress(100);
      // TO SHOW THE PROGRESS BAR REACHING 100% FOR A MOMENT
      setTimeout(() => setLoadingPhase(undefined), 150); // Reset loading state
    });
    socket.on(
      S2CEvent.CREDENTIALS_SUCCESS,
      (data: { message: string; twoFactorHandling: TwoFactorHandling }) => {
        // If there is no available 2FA method the backend sends an error message, there is no need to check that at least one method is available here
        // at the moment if the two factor selection should be skipped it is because an otp input is displayed in the UI.
        // this is the reason why if neither SMS nor OTP is available the error state is reached
        const nextStep =
          data.twoFactorHandling.automaticallySelected &&
          (data.twoFactorHandling.automaticallySelected === TwoFactorAuth.SMS ||
            data.twoFactorHandling.automaticallySelected === TwoFactorAuth.OTP)
            ? LoginStep.otpInput
            : LoginStep.twoFactorSelection;
        setStep(nextStep);
        setTwoFactorHandling(data.twoFactorHandling);
        setLoadingPhase(undefined);
      },
    );
    socket.on(S2CEvent.LOGIN_COMPLETED, () => {
      setStep(LoginStep.authorisationAndFinalisation);
      setLoadingPhase(undefined); // Reset loading state
    });
    socket.on(S2CEvent.ERROR, (data: { code: string; message: string }) => {
      console.error('Error received from the server:', data);
      setIsQrSrcValid(false);
      if (
        data.code === 'ERR_GENERIC' ||
        data.code === 'CREDENTIALS_LOGIN_FATALLY_FAILED' ||
        data.code === 'FISCAL_DATA_ACCESS_GRANTING_FAILED'
      ) {
        setStep(LoginStep.error);
      } else {
        // show error in form fields adding a banner on top of the form
        authForm.formProps.resetField('password');
        authForm.formProps.setError('username', {
          message: undefined,
        });
        authForm.formProps.setError('password', {
          message: undefined,
        });
        setLoadingPhase(undefined);
      }
    });
    return () => {
      socket.off(S2CEvent.SESSION_STARTED);
      socket.off(S2CEvent.CREDENTIALS_SUCCESS);
      socket.off(S2CEvent.ERROR);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { qrImageSrc, isQrSrcValid, loadingPhase, authForm, submit, progress };
}
