import React, { useContext, useEffect } from 'react';
import { C2SEvent, LoginStep, S2CEvent } from '../types';
import { useForm } from '@xtreamsrl/react-forms';
import * as yup from 'yup';
import { LoginContext } from './useLogin';

export function useOtpInputStepEventsHandler() {
  const loginContext = useContext(LoginContext);
  if (!loginContext || !loginContext.socket)
    throw new Error(
      'useOtpInputStepEventsHandler must be used within a LoginContext and socket cannot be null.',
    );

  const { socket, setStep } = loginContext;
  const [loading, setLoading] = React.useState<boolean>(false);

  const otpForm = useForm({
    initialValues: { otp: '' },
    validationSchema: yup.object().shape({
      otp: yup.string().required('errors.required'),
    }),
    mode: 'onBlur',
  });

  const handleSendOTP = (otpCredentials: string) => {
    setLoading(true);
    socket.emit(C2SEvent.OTP_VERIFY, {
      otp: otpCredentials.trim(),
    });
  };

  const submit = otpForm.formProps.handleSubmit((values, event) => {
    event?.preventDefault();
    handleSendOTP(values.otp);
  });

  useEffect(() => {
    socket.on(S2CEvent.LOGIN_COMPLETED, () => {
      setLoading(false); // Reset loading state
      setStep(LoginStep.authorisationAndFinalisation);
    });
    socket.on(S2CEvent.ERROR, (data: { code: string; message: string }) => {
      console.error('Error received from the server:', data);
      setLoading(false);
      if (data.code === 'OTP_VERIFICATION_FAILED') {
        otpForm.formProps.resetField('otp');
        otpForm.formProps.setError('otp', { message: undefined });
        otpForm.formProps.setFocus('otp');
        return;
      }
      setStep(LoginStep.error);
    });

    return () => {
      socket.off(S2CEvent.LOGIN_COMPLETED);
      socket.off(S2CEvent.ERROR);
    };
  });
  return { loading, otpForm, submit };
}
