import { config } from '@xtreamsrl/react-config';
import * as yup from 'yup';
import { documentTypes } from '../../requests/types';

export const retrievalStatus = {
  STARTED: 'STARTED',
  PARTIAL: 'PARTIAL',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR',
} as const;

const competenceSchema = yup.object().shape({
  type: yup.string().oneOf(['dateRange', 'year']).required(),
  data: yup
    .object()
    .shape({
      from: yup.string().optional(),
      to: yup.string().optional(),
      year: yup.string().optional(),
    })
    .required(),
});

const temporalReferenceSchema = yup.object().shape({
  year: yup.string().required(),
  competence: competenceSchema.nullable(),
});

const docResponseSchema = yup.object().shape({
  id: yup.string().required(),
  filename: yup.string().required(),
  type: yup.string().oneOf(Object.values(documentTypes)).required(),
  hasFile: yup.boolean().required(),
  retrievalStatus: yup
    .string()
    .oneOf(Object.values(retrievalStatus))
    .required(),
  temporalReference: temporalReferenceSchema.required(),
});

export type DocResponse = yup.InferType<typeof docResponseSchema>;

export async function getDocumentsList(token: string): Promise<DocResponse[]> {
  const beValues = await fetch(`${config.baseUrl}/demo/documents`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  }).then((res) => res.json() as Promise<DocResponse[]>);

  await yup.array(docResponseSchema).validate(beValues);

  return beValues;
}
