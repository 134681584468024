import React, { PropsWithChildren } from 'react';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { styled } from '@xtreamsrl/react-ui-kit/styles';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import {
  ErrorSolidIcon,
  InfoSolidIcon,
  SuccessSolidIcon,
  WarningSolidIcon,
} from '@cassetto/icons';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';

const CalloutBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'status',
})<{
  status: 'success' | 'error' | 'warning' | 'info';
}>(({ theme, status }) => {
  const baseColor = {
    success: theme.palette.success,
    error: theme.palette.error,
    warning: theme.palette.warning,
    info: theme.palette.info,
  }[status];

  return {
    padding: `${theme.spacing('sm-8')} ${theme.spacing('sm-8')} ${theme.spacing('sm-8')} ${theme.spacing('md-1')}`,
    borderRadius: theme.spacing('sm-2'),
    backgroundColor: baseColor[2],
    borderColor: baseColor[11],
    borderWidth: 1,
    borderStyle: 'solid',
  };
});

type Props = { status: 'info' | 'warning' | 'error' | 'success'; text: string };
const Callout: React.FC<PropsWithChildren<Props>> = ({
  status,
  text,
  children,
}) => {
  const icon = {
    success: <SuccessSolidIcon />,
    error: <ErrorSolidIcon />,
    warning: <WarningSolidIcon />,
    info: <InfoSolidIcon />,
  }[status];

  return (
    <CalloutBox status={status}>
      <Flex gap="sm-6" alignItems="center">
        {icon}
        <Typography variant="body/sm/semibold">{text}</Typography>
      </Flex>
      <Flex direction="column" marginTop="sm-2" gap="sm-2" marginLeft="28px">
        {children}
      </Flex>
    </CalloutBox>
  );
};

Callout.displayName = 'Callout';
export default Callout;
