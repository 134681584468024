import { avaliableSpids } from '../../requests/components/Login/spidsConfig';
import {
  BEDataRequest,
  Document,
  PlatformAndDocuments,
  Platforms,
  PlatformStatus,
  platformStatus,
  RequestDocumentType,
  SupportedOtherAuth,
  SupportedProvider,
  SupportedSpid,
} from '../../requests/types';

export function exhaustiveCheck(_x: never, callback: () => never): never {
  callback();
}

export function toMoreDescriptiveName(type: RequestDocumentType): string {
  switch (type) {
    case 'F24':
      return 'Modello F24';
    case 'ISA':
      return 'Punteggio ISA';
    case 'ANAGRAFICA':
      return 'Anagrafica';
    case 'DICHIARAZIONE_IVA':
      return 'Dichiarazione IVA';
    case 'U60':
      return 'Modello U60/760';
    case 'IRAP':
      return 'IRAP';
    case 'MODELLO770':
      return 'Modello 770';
    case 'LIQUIDAZIONE_IVA':
      return 'Liquidazione IVA';
    case 'FATTURA':
      return 'Fatture';
    case 'CERTIFICAZIONE_UNICA':
      return 'Certificazione Unica';
    case 'DICHIARAZIONE_REDDITI':
      return 'Modello Unico';
    case 'RIMBORSO':
      return 'Rimborsi';
    case 'ESTRATTO_DI_RUOLO':
      return 'Estratto di ruolo';
    case 'F24_RIEPILOGATIVO':
      return 'Riepilogativi F24';
    case 'ESTRATTO_CONTRIBUTIVO':
      return 'Estratto conto contributivo';
    case 'MODELLO730':
      return 'Modello 730';
    default:
      exhaustiveCheck(type, () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        throw new Error(`Unknown document type ${type as any}`);
      });
  }
}

export const providerToDisplayName = {
  [SupportedOtherAuth.cie]: 'CIE',
  [SupportedSpid.arubaId]: 'Aruba ID',
  [SupportedSpid.infocertId]: 'Infocert',
  [SupportedSpid.posteId]: 'Poste Italiane',
  [SupportedSpid.sielteId]: 'Sielte ID',
  [SupportedSpid.spiditalia]: 'SPID Italia',
  [SupportedSpid.etnaId]: 'Etna ID',
  [SupportedSpid.timId]: 'TIM ID',
  [SupportedSpid.infocamereId]: 'Infocamere',
  [SupportedSpid.namirialId]: 'Namirial',
  [SupportedSpid.teamsystem]: 'TeamSystem',
  [SupportedSpid.lepidaId]: 'Lepida ID',
  [SupportedSpid.intesiId]: 'Intesi Group',
} satisfies Record<SupportedProvider, string>;

export function getPlatformDocuments(
  request: BEDataRequest,
): PlatformAndDocuments[] {
  if (!request.loginAuthPlatforms) {
    throw new Error('loginAuthPlatforms is undefined');
  }

  const result: {
    platform: Platforms;
    documents: Document[];
  }[] = [];
  // Iterate over the platforms in loginAuthPlatforms
  for (const platform in request.loginAuthPlatforms) {
    const platformKey = platform as Platforms;

    if (!request.loginAuthPlatforms[platformKey]) {
      throw new Error(
        `loginAuthPlatforms for platform ${platformKey} is undefined`,
      );
    }

    const platformData = request.loginAuthPlatforms[platformKey];

    // Ensure the platformData is valid
    if (!platformData || !Array.isArray(platformData.docs)) {
      throw new Error(
        // eslint-disable-next-line
        `Invalid structure in loginAuthPlatform for platform: ${platformKey}\nData structure:\n${platformData}`,
      );
    }

    const platformDocs = platformData.docs;

    // Find matching documents in request.documents (full document objects)
    const matchingDocuments = request.documents.filter((doc) =>
      platformDocs.includes(doc.type),
    );

    // Push to result array if there are any matching documents
    if (matchingDocuments.length > 0) {
      result.push({
        platform: platformKey,
        documents: matchingDocuments,
      });
    }
  }

  return result;
}

export function getProviderLogos(
  selectedProvider?: SupportedSpid | SupportedOtherAuth,
) {
  if (!selectedProvider) {
    return undefined;
  }

  const spid = avaliableSpids.find(
    (spid) => spid.provider === selectedProvider,
  );

  const chosenProvider = spid ? '/assets/spid.png' : '/assets/cie.png';
  const specificLogo = spid?.logo ?? undefined;

  return { chosenProvider, specificLogo };
}

export function isInFinalStatus(status: PlatformStatus): boolean {
  return (
    status === platformStatus.DOWNLOAD_COMPLETED ||
    status === platformStatus.DOWNLOAD_PARTIAL ||
    status === platformStatus.DOWNLOAD_FAILED
  );
}
