import {
  PlatformAndDocuments,
  Platforms,
  platformStatus,
  PlatformStatus,
  PlatformStatusInfo,
} from '../types';

export function isStatePotentiallyChangingServerSide(
  status: PlatformStatus | undefined,
) {
  return (
    status === platformStatus.DOWNLOADING ||
    status === platformStatus.AUTH_SUCCESS ||
    status === platformStatus.AUTH_IN_PROGRESS
  );
}

export function shouldRefetch({
  lastAccessedPlatform,
  platformStatuses,
  platformAndDocuments,
}: {
  lastAccessedPlatform: Platforms | undefined;
  platformAndDocuments: PlatformAndDocuments[];
  platformStatuses: Partial<Record<Platforms, PlatformStatusInfo>>;
}) {
  return (
    (lastAccessedPlatform &&
      isStatePotentiallyChangingServerSide(
        platformStatuses[lastAccessedPlatform]?.status,
      )) ||
    platformAndDocuments
      .filter((p) => p.platform !== lastAccessedPlatform)
      .map((p) => p.platform)
      .some((p) => platformStatuses[p]?.status === platformStatus.DOWNLOADING)
  );
}

export function isStateCreatedOrAuthInProgress(state: PlatformStatus) {
  return state === 'AUTH_IN_PROGRESS' || state === 'CREATED';
}
