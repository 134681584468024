import React, { PropsWithChildren } from 'react';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { useDocumentsListQuery } from '../../queries/getDocumentsListQuery';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import Callout from '../../../requests/components/Callout/Callout';
import { fetchFile } from '../../queries/getFileQuery';
import { RoundedBox } from '../../../requests/components/BoxVariants';

type Props = { token: string | undefined };
const DownloadedDocumentsList: React.FC<PropsWithChildren<Props>> = ({
  token,
}) => {
  if (!token)
    throw new Error('DownloadedDocumentsList must be used using a token.');
  const { data, isLoading, isError } = useDocumentsListQuery(token);

  if (data) {
    return (
      <Flex
        flex={1}
        direction="column"
        padding="lg-2"
        gap="xl-1"
        alignItems="center"
      >
        <Flex direction="column" gap="sm-4">
          <Typography variant="header/sm/semibold" textAlign="center">
            Visualizzazione Documenti
          </Typography>
          <Typography variant="body/base/regular" textAlign="center">
            I documenti recuperati sono presenti nella lista che segue. Se
            desideri scaricare un documento, clicca sul pulsante "Scarica". Se
            questo non è presente, il documento originale non è disponibile.
          </Typography>
        </Flex>
        <Box>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            paddingY="sm-3"
            paddingRight="sm-3"
            paddingLeft="sm-6"
          >
            <Flex gap="sm-6" flex={9} alignItems="center" overflow="hidden">
              <Typography
                style={{ flex: 2 }}
                variant="body/base/semibold"
                textOverflow="ellipsis"
              >
                File
              </Typography>
              <Typography
                style={{ flex: 1 }}
                variant="body/base/semibold"
                textAlign="center"
              >
                Anno
              </Typography>
            </Flex>
            <Box flex={2} />
          </Flex>
          {data
            .sort((a, b) => (b.hasFile ? 1 : 0) - (a.hasFile ? 1 : 0))
            .map((document, index) => (
              <RoundedBox
                index={index}
                total={data.length}
                key={document.id}
                sx={{ width: '60vw', background: 'white' }}
              >
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  paddingY="sm-3"
                  paddingRight="sm-3"
                  paddingLeft="sm-6"
                >
                  <Flex
                    gap="sm-6"
                    flex={9}
                    alignItems="center"
                    overflow="hidden"
                  >
                    <Typography
                      style={{ flex: 2, overflow: 'hidden' }}
                      variant="body/base/medium"
                      textOverflow="ellipsis"
                    >
                      {document.filename}
                    </Typography>
                    <Typography
                      style={{ flex: 1 }}
                      variant="body/base/regular"
                      textAlign="center"
                    >
                      {document.temporalReference.competence?.data.year
                        ? document.temporalReference.competence.data.year
                        : document.temporalReference.year}
                    </Typography>
                  </Flex>
                  <Box flex={2} textAlign="right">
                    {document.hasFile && (
                      <Button
                        variant="tinted"
                        size="sm"
                        onClick={() =>
                          void fetchFile({
                            token,
                            documentId: document.id,
                          }).then((blob) => {
                            const url = window.URL.createObjectURL(blob);

                            // Create a temporary anchor element to trigger download
                            const a = window.document.createElement('a');
                            a.href = url;

                            // You can set the file name via the 'download' attribute, or it may come from the Content-Disposition header
                            a.download = document.filename; // Adjust the filename and extension as necessary

                            // Append the anchor element to the document body and trigger the download
                            window.document.body.appendChild(a);
                            a.click();

                            // Clean up: remove the anchor element and release the object URL
                            window.document.body.removeChild(a);
                            window.URL.revokeObjectURL(url);
                          })
                        }
                      >
                        Scarica file
                      </Button>
                    )}
                  </Box>
                </Flex>
              </RoundedBox>
            ))}
        </Box>
      </Flex>
    );
  }

  if (isError)
    return (
      <Flex
        flex={1}
        direction="column"
        padding="lg-2"
        gap="xl-1"
        alignItems="center"
      >
        <Flex direction="column" gap="sm-4">
          <Typography variant="header/sm/semibold" textAlign="center">
            Visualizzazione Documenti
          </Typography>
          <Typography variant="body/base/regular" textAlign="center">
            I documenti recuperati sono presenti nella lista che segue. Se
            desideri scaricare un documento, clicca sul pulsante "Scarica". Se
            questo non è presente, il documento originale non è disponibile.
          </Typography>
        </Flex>
        <Callout status="error" text="Errore">
          <Typography variant="body/xs/regular" color="grey.11">
            Si è verificato un errore durante il recupero dei documenti. Se hai
            aggiornato la pagina, hai perso l'accesso ai documenti scaricati.
          </Typography>
        </Callout>
      </Flex>
    );

  if (isLoading) return <Box>Caricamento...</Box>;
};

DownloadedDocumentsList.displayName = 'DownloadedDocumentsList';
export default DownloadedDocumentsList;
