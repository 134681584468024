import React, { PropsWithChildren } from 'react';
import FullScreenCard from '../FullScreenCard';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { Company } from '../../types';

interface LeavingCardProps {
  requestFrom: Company;
  heading: string;
  text: string;
}

const LeavingCard: React.FC<PropsWithChildren<LeavingCardProps>> = ({
  requestFrom,
  heading,
  text,
  children,
}) => {
  return (
    <FullScreenCard>
      <Flex
        height="100%"
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap="md-5"
        paddingTop="xl-1"
        paddingBottom="md-8"
        paddingX="md-8"
      >
        <img
          alt={`Logo ${requestFrom.name}`}
          style={{
            height: '40px',
            objectFit: 'contain',
          }}
          src={requestFrom.logo}
        />
        <Flex direction="column" gap="sm-4">
          <Typography variant="body/xl/semibold" textAlign="center">
            {heading}
          </Typography>
          <Typography variant="body/base/regular" textAlign="center">
            {text}
          </Typography>
        </Flex>
        {children}
      </Flex>
    </FullScreenCard>
  );
};

LeavingCard.displayName = 'LeavingCard';
export default LeavingCard;
