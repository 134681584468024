import { SupportedSpid } from '../../types';

export const avaliableSpids = [
  {
    provider: SupportedSpid.posteId,
    logo: 'https://iampe.agenziaentrate.gov.it/sam/ext/UIKit4/spid-sp-access-button-master/src/production/img/spid-idp-posteid.svg',
  },
  {
    provider: SupportedSpid.sielteId,
    logo: 'https://iampe.agenziaentrate.gov.it/sam/ext/UIKit4/spid-sp-access-button-master/src/production/img/spid-idp-sielteid.svg',
  },
  {
    provider: SupportedSpid.lepidaId,
    logo: 'https://iampe.agenziaentrate.gov.it/sam/ext/UIKit4/spid-sp-access-button-master/src/production/img/spid-idp-lepidaid.svg',
  },
  {
    provider: SupportedSpid.namirialId,
    logo: 'https://iampe.agenziaentrate.gov.it/sam/ext/UIKit4/spid-sp-access-button-master/src/production/img/spid-idp-namirialid.svg',
  },
  {
    provider: SupportedSpid.etnaId,
    logo: 'https://iampe.agenziaentrate.gov.it/sam/ext/UIKit4/spid-sp-access-button-master/src/production/img/spid-idp-etnaid.svg',
  },
  {
    provider: SupportedSpid.arubaId,
    logo: 'https://iampe.agenziaentrate.gov.it/sam/ext/UIKit4/spid-sp-access-button-master/src/production/img/spid-idp-arubaid.svg',
  },
  {
    provider: SupportedSpid.infocamereId,
    logo: 'https://iampe.agenziaentrate.gov.it/sam/ext/UIKit4/spid-sp-access-button-master/src/production/img/spid-idp-infocamereid.svg',
  },
  {
    provider: SupportedSpid.infocertId,
    logo: 'https://iampe.agenziaentrate.gov.it/sam/ext/UIKit4/spid-sp-access-button-master/src/production/img/spid-idp-infocertid.svg',
  },
  {
    provider: SupportedSpid.spiditalia,
    logo: 'https://iampe.agenziaentrate.gov.it/sam/ext/UIKit4/spid-sp-access-button-master/src/production/img/spid-idp-spiditalia.svg',
  },
  {
    provider: SupportedSpid.timId,
    logo: 'https://iampe.agenziaentrate.gov.it/sam/ext/UIKit4/spid-sp-access-button-master/src/production/img/spid-idp-timid.svg',
  },
  {
    provider: SupportedSpid.intesiId,
    logo: 'https://iampe.agenziaentrate.gov.it/sam/ext/UIKit4/spid-sp-access-button-master/src/production/img/spid-idp-intesigroupspid.svg',
  },
  {
    provider: SupportedSpid.teamsystem,
    logo: 'https://iampe.agenziaentrate.gov.it/sam/ext/UIKit4/spid-sp-access-button-master/src/production/img/spid-idp-teamsystemid.svg',
  },
] as const;
