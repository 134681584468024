import { config } from '@xtreamsrl/react-config';
import { BEDataRequest, DataRequest } from '../types';
import { getPlatformDocuments } from '../../_shared/utils/utils';

export class CassettoException extends Error {
  constructor(
    public res: Response,
    public code: number,
    message?: string,
  ) {
    super(message);
  }
}

export async function getRequestInfo(requestId: string): Promise<DataRequest> {
  const response = await fetch(
    `${config.baseUrl}/documents-requests/${requestId}`,
    {
      method: 'GET',
    },
  );

  if (!response.ok) {
    throw new CassettoException(response, response.status, response.statusText);
  }

  const request = (await response.json()) as BEDataRequest;

  const associationBetweenDocumentsAndPlatforms = getPlatformDocuments(request);

  // Check that all platforms in platformAndDocuments have a state in platformStatus
  const allPlatformsHaveStatus = associationBetweenDocumentsAndPlatforms.every(
    (platformAndDocs) => !!request.platformStatus[platformAndDocs.platform],
  );

  if (!allPlatformsHaveStatus) {
    throw new Error(
      'Some platforms do not have a corresponding status in platformStatus',
    );
  }

  return {
    ...request,
    platformAndDocuments: associationBetweenDocumentsAndPlatforms,
  };
}
