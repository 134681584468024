import {
  Platforms,
  platformToDisplayName,
  SupportedOtherAuth,
  SupportedProvider,
} from '../../types';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { avaliableSpids } from './spidsConfig';
import { useState } from 'react';
import { styled } from '@xtreamsrl/react-ui-kit/styles';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { SpidIcon, CieIcon } from '@cassetto/icons';

export const OptionContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing('sm-6'),
  cursor: 'pointer',
  borderRadius: '8px',
  borderColor: theme.palette.grey[3],
  borderStyle: 'solid',
  borderWidth: '1px',
  width: '30vw',

  ':hover': {
    borderColor: theme.palette.brand[9],
    backgroundColor: theme.palette.brand[3],
  },
}));

export function ProviderSelector({
  selectProvider,
  platform,
}: {
  selectProvider: (provider: SupportedProvider) => void;
  platform: Platforms;
}) {
  const [furtherSelectionNeeded, setFurtherSelectionNeeded] = useState(false);

  return (
    <Flex
      direction="column"
      alignItems="center"
      gap="xl-2"
      padding="lg-2"
      flex={1}
    >
      <Flex direction="column" alignItems="center" gap="sm-4">
        <Typography variant="header/sm/semibold">
          Accedi ad {platformToDisplayName[platform]}
        </Typography>
        <Typography variant="body/base/regular" textAlign="center">
          Seleziona la modalità di accesso che preferisci. Per garantire la
          massima sicurezza, verrà richiesta un'autenticazione a due fattori.
          <br />
          Assicurati di avere tutto il necessario per completare questo
          passaggio
        </Typography>
      </Flex>
      <Flex direction="column" gap="sm-8">
        <Box
          border="1px solid"
          padding="md-1"
          style={{
            borderRadius: '4px',
            borderColor: 'grey.6',
            background: 'grey.1',
          }}
        >
          {/* FLEX NEEDED TO APPLY GAP */}
          <Flex direction="column" gap="sm-5">
            <Flex direction="row" gap="sm-8">
              <img src="/assets/spid.png" alt="SPID logo" height="44" />
              <Flex direction="column" gap="sm-8">
                <Typography variant="body/xxl/semibold">SPID</Typography>
                <Typography variant="body/sm/regular" color="grey.11">
                  SPID, il Sistema Pubblico di Identità Digitale, è il sistema
                  di accesso che consente di utilizzare, con un'identità
                  digitale unica, i servizi online della Pubblica
                  Amministrazione e dei privati accreditati. Se sei già in
                  possesso di un'identità digitale, accedi con le credenziali
                  del tuo gestore. Se non hai ancora un'identità digitale,
                  richiedila ad uno dei gestori.
                </Typography>
              </Flex>
            </Flex>
            {/*  END OF OPTION'S TEXTUAL CONTENT */}
            <Box alignSelf="flex-end">
              <Button
                variant="filled"
                leftIcon={
                  <Icon>
                    <SpidIcon />
                  </Icon>
                }
                color="info"
                style={{ textTransform: 'none' }}
                onClick={() =>
                  setFurtherSelectionNeeded(!furtherSelectionNeeded)
                }
              >
                Entra con SPID
              </Button>
              <Box
                style={{
                  display: furtherSelectionNeeded ? 'flex' : 'none',
                  flexDirection: 'column',
                  position: 'absolute',
                  width: '172px', // SHOULD I FIX THIS?
                  zIndex: 1,
                  border: 'solid 1px #DFE3E6',
                  borderRadius: '6px',
                }}
              >
                {avaliableSpids.map((spid, index) => (
                  <Box
                    key={spid.provider}
                    onClick={() => selectProvider(spid.provider)}
                    backgroundColor="grey.1"
                    borderBottom={
                      index === avaliableSpids.length - 1
                        ? 0
                        : 'solid 1px #DFE3E6'
                    }
                    paddingTop="sm-7"
                    paddingBottom="sm-5"
                    paddingX="sm-5"
                    textAlign="center"
                    style={{
                      cursor: 'pointer',
                      ':hover': { backgroundColor: 'grey.3' },
                    }}
                  >
                    <img
                      src={spid.logo}
                      alt={`${spid.provider} logo`}
                      style={{
                        height: '25px',
                        maxWidth: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Flex>
        </Box>
        <Box
          border="1px solid"
          padding="md-1"
          style={{
            borderRadius: '4px',
            borderColor: 'grey.6',
            background: 'grey.1',
          }}
        >
          {/* FLEX NEEDED TO APPLY GAP */}
          <Flex direction="column" gap="sm-5">
            <Flex direction="row" gap="sm-8">
              <img src="/assets/cie.png" alt="CIE logo" height="44" />
              <Flex direction="column" gap="sm-8">
                <Typography variant="body/xxl/semibold">CIE</Typography>
                <Typography variant="body/sm/regular" color="grey.11">
                  La Carta di Identità Elettronica (CIE) è il documento
                  personale che attesta l'identità del cittadino.
                  <br />
                  Dotata di microprocessore, oltre a comprovare l'identità
                  personale, permette l'accesso ai servizi digitali della
                  Pubblica Amministrazione
                </Typography>
              </Flex>
            </Flex>
            {/*  END OF OPTION'S TEXTUAL CONTENT */}
            <Box alignSelf="flex-end">
              <Button
                variant="filled"
                color="info"
                leftIcon={
                  <Icon>
                    <CieIcon />
                  </Icon>
                }
                style={{ textTransform: 'none' }}
                onClick={() => selectProvider(SupportedOtherAuth.cie)}
              >
                Entra con CIE
              </Button>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}
