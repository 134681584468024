import React from 'react';
import { TwoFactorAuth } from '../../../types';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { Radio } from '@xtreamsrl/react-ui-kit/Radio';

const TwoFactorAuthenticationOption: React.FC<{
  name: TwoFactorAuth;
  title: string;
  description: string;
  selected: TwoFactorAuth | undefined;
  setSelected: (selected: TwoFactorAuth) => void;
}> = ({ name, title, description, selected, setSelected }) => {
  return (
    <Box
      width="100%"
      onClick={() => setSelected(name)}
      style={{
        ':hover': {
          cursor: 'pointer',
          backgroundColor: 'grey.2',
          borderRadius: 'inherit',
        },
      }}
    >
      <Flex padding="sm-8" gap="md-5" justifyContent="space-between">
        <Flex direction="column">
          <Typography variant="body/base/semibold">{title}</Typography>
          <Typography variant="body/xs/regular" color="grey.11">
            {description}
          </Typography>
        </Flex>
        <Radio checked={selected === name} />
      </Flex>
    </Box>
  );
};

TwoFactorAuthenticationOption.displayName = 'TwoFactorAuthenticationOption';

export default TwoFactorAuthenticationOption;
