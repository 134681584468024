import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React from 'react';
import { TwoFactorAuth } from '../../../types';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Radio } from '@xtreamsrl/react-ui-kit/Radio';

const OtpGeneratorOption: React.FC<{
  title: string;
  description: string;
  selected: TwoFactorAuth | undefined;
  setSelected: (selected: TwoFactorAuth) => void;
  otpGeneratorId: string;
  selectedOtpGenerator: string | undefined;
  setSelectedOtpGenerator: (selectedOtpGenerator: string) => void;
}> = ({
  title,
  description,
  selected,
  setSelected,
  otpGeneratorId,
  selectedOtpGenerator,
  setSelectedOtpGenerator,
}) => {
  return (
    <Box
      width="100%"
      onClick={() => {
        setSelected(TwoFactorAuth.OTP);
        setSelectedOtpGenerator(otpGeneratorId);
      }}
      style={{
        ':hover': {
          cursor: 'pointer',
          backgroundColor: 'grey.2',
          borderRadius: 'inherit',
        },
      }}
    >
      <Flex padding="sm-8" gap="md-5" justifyContent="space-between">
        <Flex direction="column" gap="sm-2">
          <Typography variant="body/base/semibold">{title}</Typography>
          <Typography variant="body/xs/regular" color="grey.11">
            {description}
          </Typography>
          <Box marginTop="sm-2">
            <Typography variant="body/xs/medium">
              OTP ID: {otpGeneratorId}
            </Typography>
          </Box>
        </Flex>
        <Radio
          checked={
            selected === TwoFactorAuth.OTP &&
            selectedOtpGenerator === otpGeneratorId
          }
        />
      </Flex>
    </Box>
  );
};

OtpGeneratorOption.displayName = 'OtpGeneratorOption';
export default OtpGeneratorOption;
