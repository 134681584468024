import { config } from '@xtreamsrl/react-config';
import * as yup from 'yup';

interface CreateDemoPFRequestData {
  email: string;
  target: 'PF';
  fiscalCode: string;
}

interface CreateDemoPGRequestData {
  email: string;
  target: 'PG';
  name: string;
  vatNumber: string;
}

type CreateDemoRequestData = CreateDemoPFRequestData | CreateDemoPGRequestData;

const createDemoRequestResponseSchema = yup.object().shape({
  id: yup.string().required(),
  url: yup.string().required(),
});

type CreateDemoRequestResponse = yup.InferType<
  typeof createDemoRequestResponseSchema
>;

export async function createDemoRequest(
  data: CreateDemoRequestData,
): Promise<string> {
  const input = {
    email: data.email,
    target: {
      type: data.target,
      ...(data.target === 'PF'
        ? { fiscalCode: data.fiscalCode }
        : { vatNumber: data.vatNumber, name: data.name }),
    },
  };

  const beValues = await fetch(`${config.baseUrl}/demo/start`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      charset: 'utf-8',
    },
    body: JSON.stringify(input),
  }).then((res) => res.json() as Promise<CreateDemoRequestResponse>);

  await createDemoRequestResponseSchema.validate(beValues);

  return beValues.id;
}
