import { styled } from '@xtreamsrl/react-ui-kit/styles';
import { Box } from '@xtreamsrl/react-ui-kit/Box';

export const RoundedBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'index' && prop !== 'total',
})<{
  index: number;
  total: number;
}>(({ theme, index, total }) => ({
  border: '1px solid',
  borderColor: theme.palette.grey[6],
  borderTopLeftRadius: index === 0 ? '8px' : '0px', // Rounded top for first item
  borderTopRightRadius: index === 0 ? '8px' : '0px', // Rounded top for first item
  borderBottomLeftRadius: index === total - 1 ? '8px' : '0px', // Rounded bottom for last item
  borderBottomRightRadius: index === total - 1 ? '8px' : '0px', // Rounded bottom for last item
  borderBottomWidth: index === total - 1 ? '1px' : '0px', // No bottom border except for last item
}));
