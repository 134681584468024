import { configureEnvVars } from '@xtreamsrl/react-config';
import { configureQueryClient } from '@xtreamsrl/react-query';

configureQueryClient({ defaultOptions: { queries: { staleTime: Infinity } } });

configureEnvVars((env) => {
  return {
    baseUrl: env.VITE_API_BASE_URL,
    isDemo: env.VITE_IS_DEMO === 'true',
    posthogKey: env.VITE_PUBLIC_POSTHOG_KEY,
    posthogHost: env.VITE_PUBLIC_POSTHOG_HOST,
  };
});
