import { createMutation } from '@xtreamsrl/react-query';
import { acceptTerms } from '../apis/acceptTerms';

const acceptTermsMutation = createMutation({
  mutatingFunction: (requestId: string) => acceptTerms(requestId),
  options: {
    networkMode: 'always',
  },
});

export const useAcceptTermsMutations = acceptTermsMutation.hook;
