import { config } from '@xtreamsrl/react-config';

export async function getFile(
  token: string,
  documentId: string,
): Promise<Blob> {
  const response = await fetch(
    `${config.baseUrl}/demo/documents/${documentId}`,
    {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    },
  );

  if (!response.ok) {
    throw new Error('Failed to download document');
  }

  return await response.blob();
}
