import React from 'react';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { useAuthorisationAndFinalisationEventsHandler } from '../../../hooks/useAuthorisationAndFinalisationEventsHandler';
import { FinalisationStep } from './index';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import Callout from '../../Callout/Callout';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { SupportedProvider } from '../../../types';
import { providerToDisplayName } from '../../../../_shared/utils/utils';

type AuthorisationAndFinalisationStepProps = {
  onLoginSuccess: (token: string) => void;
  onLoginFailure: () => void;
  selectedProvider: SupportedProvider;
};

const AuthorisationAndFinalisationStep: React.FC<
  AuthorisationAndFinalisationStepProps
> = ({ onLoginSuccess, onLoginFailure, selectedProvider }) => {
  const { innerSteps, retry } = useAuthorisationAndFinalisationEventsHandler({
    onLoginSuccess,
  });

  const error = Object.values(innerSteps).some(
    (innerStep) => innerStep === 'error',
  );

  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="space-around"
      gap="sm-6"
      flex={5}
    >
      <FinalisationStep
        step="authentication"
        state={innerSteps.authentication}
      />
      <FinalisationStep step="authorisation" state={innerSteps.authorisation} />
      <FinalisationStep
        step="headersAndCookies"
        state={innerSteps.headersAndCookies}
      />

      {error ? (
        <>
          <Callout
            status="error"
            text="Si è verificato un errore durante l'accesso alla piattaforma"
          >
            <Typography variant="body/sm/regular">
              Questo potrebbe essere dovuto a: provider{' '}
              <strong>
                {providerToDisplayName[selectedProvider]} momentaneamente non
                disponibile
              </strong>
              , <strong>credenziali errate</strong> o{' '}
              <strong>mancanza dei permessi</strong> per accedere alla
              piattaforma o al dato richiesto.
            </Typography>
          </Callout>
          <Flex justifyContent="space-between" width="100%">
            {/* The style is required due to the fact that mui adds absolute positioning for buttons inside the modal */}
            <Button
              variant="plain"
              size="lg"
              color="grey"
              disabled={!error}
              onClick={retry}
              style={{
                position: 'relative !important',
                top: '0 !important',
                right: '0 !important',
              }}
            >
              Riprova
            </Button>
            <Button
              variant="plain"
              size="lg"
              color="brand"
              disabled={!error}
              onClick={onLoginFailure}
              rightIcon={<Icon name="ArrowRight" />}
              style={{
                position: 'relative !important',
                top: '0 !important',
                right: '0 !important',
              }}
            >
              Accedi ad un'altra piattaforma
            </Button>
          </Flex>
        </>
      ) : null}
    </Flex>
  );
};

AuthorisationAndFinalisationStep.displayName =
  'AuthorisationAndFinalisationStep';
export default AuthorisationAndFinalisationStep;
