export const translations = {
  it: {
    errors: {
      required: 'Campo obbligatorio.',
      email: 'Inserisci un indirizzo email valido.',
      fiscalCode: 'Inserisci un codice fiscale valido.',
      vatNumber: 'Inserisci una partita IVA valida.',
    },
  },
};
