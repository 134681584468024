import React, { useEffect } from 'react';
import {
  LoginStep,
  Platforms,
  platformToDisplayName,
  SupportedOtherAuth,
} from '../../types';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { LoginContext, useLogin } from '../../hooks/useLogin';
import { ProviderSelector } from './ProviderSelector';
import { AuthenticationStep } from './AuthenticationStep';
import { TwoFactorSelectionStep } from './TwoFactorSelectionStep';
import { OtpInputStep } from './OtpInputStep';
import { AuthorisationAndFinalisationStep } from './AuthorisationAndFinalisationStep';
import { Modal } from '@xtreamsrl/react-ui-kit/Modal';
import {
  getProviderLogos,
  providerToDisplayName,
} from '../../../_shared/utils/utils';
import LoginFatalError from './LoginFatalError';

export const inputSize = '25vw';

type Props = {
  onLoginSuccess: (token: string) => void;
  onLoginFailure: () => void;
  platform: Platforms;
};

const Login: React.FC<Props> = ({
  onLoginSuccess,
  onLoginFailure,
  platform,
}) => {
  const {
    selectedProvider,
    selectProvider,
    step,
    setStep,
    twoFactorHandling,
    setTwoFactorHandling,
    socket,
    retry,
  } = useLogin(platform);

  const logos = getProviderLogos(selectedProvider);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (step <= LoginStep.authentication) return;
      e.preventDefault();
      return (e.returnValue = 'Prova a chiudere la pagina');
    };

    // Add the event listener when the component is mounted
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [step]);

  return (
    <>
      {!selectedProvider ? (
        <ProviderSelector selectProvider={selectProvider} platform={platform} />
      ) : (
        <Flex direction="column" flex={1} backgroundColor="grey.2">
          <Flex
            width="100%"
            direction="row"
            justifyContent="space-between"
            paddingX="sm-8"
            paddingY="md-1"
            borderBottom="1px solid #F1F3F5"
            backgroundColor="grey.1"
            alignItems="center"
          >
            <img
              src={logos?.chosenProvider}
              style={{ height: '44px' }}
              alt={`Logo ${selectedProvider === SupportedOtherAuth.cie ? 'CIE' : 'SPID'}`}
            />
            {logos?.specificLogo && (
              <img
                style={{ height: '50px' }}
                src={logos?.specificLogo}
                alt={`Logo dello SPID provider ${providerToDisplayName[selectedProvider]}`}
              />
            )}
          </Flex>
          <LoginContext.Provider
            value={{
              socket,
              setStep,
              retry,
              platform,
            }}
          >
            <Flex direction="column" padding="lg-2" gap="xl-2">
              <Flex direction="column" gap="sm-4">
                <Typography
                  variant="body/xl/regular"
                  textAlign="left"
                  color="grey.11"
                >
                  RICHIESTA DI ACCESSO SPID DI LIVELLO 2 DA
                </Typography>
                <Typography variant="header/sm/semibold" textAlign="left">
                  {platformToDisplayName[platform]}
                </Typography>
              </Flex>
              <AuthenticationStep
                selectedProvider={selectedProvider}
                setTwoFactorHandling={setTwoFactorHandling}
              />
              <Modal
                open={
                  step >= LoginStep.twoFactorSelection ||
                  step === LoginStep.error
                }
                onClose={() => {}} // the modal should not be closed clicking outside its content
                showCloseButton={false}
              >
                <Modal.Content>
                  {step >= LoginStep.authentication && (
                    <Flex direction="column" gap="md-5">
                      <Flex
                        flex={1}
                        direction="column"
                        alignItems="center"
                        gap="sm-4"
                      >
                        <Typography variant="body/xl/semibold">
                          Autenticazione a Due Fattori (2FA)
                        </Typography>
                        <Typography
                          variant="body/xs/regular"
                          textAlign="center"
                          color="grey.11"
                          whiteSpace="break-spaces"
                        >
                          {step <= LoginStep.otpInput
                            ? 'Conferma la tua identità attraverso un secondo metodo di verifica.'
                            : 'Ti preghiamo di non chiudere o ricaricare la pagina mentre uno degli step è in esecuzione.\nAltrimenti la procedura di login verrà interrotta.'}
                        </Typography>
                      </Flex>
                      {step === LoginStep.twoFactorSelection && (
                        <TwoFactorSelectionStep
                          twoFactorHandling={twoFactorHandling}
                        />
                      )}
                      {step === LoginStep.otpInput && <OtpInputStep />}
                      {step >= LoginStep.authorisationAndFinalisation && (
                        <AuthorisationAndFinalisationStep
                          onLoginSuccess={onLoginSuccess}
                          onLoginFailure={onLoginFailure}
                          selectedProvider={selectedProvider}
                        />
                      )}
                    </Flex>
                  )}
                  {step === LoginStep.error && (
                    <LoginFatalError
                      selectedProvider={selectedProvider}
                      retry={retry}
                      onLoginFailure={onLoginFailure}
                    />
                  )}
                </Modal.Content>
              </Modal>
            </Flex>
          </LoginContext.Provider>
        </Flex>
      )}
    </>
  );
};

Login.displayName = 'Login';

export default Login;
