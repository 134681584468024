import { useContext } from 'react';

import { RequestContext } from '../../demo/hooks/useDemoRequestCreator';
import { useParams } from '@xtreamsrl/react-routing';

export function useRequestId() {
  const requestIdFromContext = useContext<string | undefined>(RequestContext);
  const { requestId: requestIdFromUrl } = useParams<{ requestId: string }>();

  const requestId = requestIdFromContext ?? requestIdFromUrl;

  if (!requestId) throw new Error('requestId is required');
  return requestId;
}
