import React, { PropsWithChildren } from 'react';
import { Box } from '@xtreamsrl/react-ui-kit/Box';

const FullScreenCard: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      style={{
        minHeight: '100vh',
        minWidth: '100%',
      }}
      backgroundColor={'brand.12'}
      padding="xl-2"
    >
      <Box
        backgroundColor="background.default"
        border="1px solid"
        paddingTop="xl-1"
        paddingLeft="md-8"
        paddingRight="md-8"
        paddingBottom="md-8"
        height="100%"
        style={{
          borderColor: 'grey.1',
          borderRadius: '8px',
          maxWidth: '900px',
          margin: 'auto',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

FullScreenCard.displayName = 'FullScreenCard';
export default FullScreenCard;
