import React, { useEffect } from 'react';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { useOtpInputStepEventsHandler } from '../../../hooks/useOtpInputStepEventsHandler';
import { FormProvider } from '@xtreamsrl/react-forms';
import { FormTextInputBasic } from '@xtreamsrl/react-form-input-kit/FormTextInputBasic';
import { Box } from '@xtreamsrl/react-ui-kit/Box';

const OtpInputStep: React.FC = () => {
  const { loading, otpForm, submit } = useOtpInputStepEventsHandler();

  useEffect(() => {
    otpForm.formProps.setFocus('otp');
  }, [otpForm.formProps]);

  return (
    <FormProvider enableDevTools={false} {...otpForm.formProps}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submit().catch((error) => {
            console.error('Failed to submit form:', error);
          });
        }}
      >
        <Flex direction="column" gap="md-1">
          <FormTextInputBasic name="otp" placeholder="OTP" disabled={loading} />
          <Box alignSelf="center">
            <Button
              type="submit"
              variant="outlined"
              loading={loading}
              color="grey"
              style={{ color: 'black' }}
            >
              Conferma
            </Button>
          </Box>
        </Flex>
      </form>
    </FormProvider>
  );
};

OtpInputStep.displayName = 'OtpInputStep';
export default OtpInputStep;
