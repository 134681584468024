import { createQuery } from '@xtreamsrl/react-query';
import { getDocumentsList } from '../apis/getDocumentsList';

const getDocumentsListQuery = createQuery(({ token }: { token: string }) => ({
  queryKey: ['documentsList', token],
  queryFn: () => getDocumentsList(token),
}));

export const useDocumentsListQuery = (token: string) =>
  getDocumentsListQuery.hook({ token });
