import { SupportedProvider } from '../../../types';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { FinalisationStep } from '../AuthorisationAndFinalisationStep';
import Callout from '../../Callout/Callout';
import { providerToDisplayName } from '../../../../_shared/utils/utils';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';

export function LoginFatalError(props: {
  selectedProvider: SupportedProvider;
  retry: () => void;
  onLoginFailure: () => void; // this is an error component, so 'successful' cannot be true
}) {
  return (
    <Flex direction="column" gap="md-5">
      <Flex flex={1} direction="column" alignItems="center" gap="sm-4">
        <Typography variant="body/xl/semibold">
          Autenticazione a Due Fattori (2FA)
        </Typography>
        <Typography
          variant="body/xs/regular"
          textAlign="center"
          color="grey.11"
        >
          Conferma la tua identità attraverso un secondo metodo di verifica.
        </Typography>
      </Flex>
      <Flex direction="column" justifyContent="space-around" gap="sm-6">
        <FinalisationStep step="authentication" state="error" />
        <FinalisationStep step="authorisation" state="todo" />
        <FinalisationStep step="headersAndCookies" state="todo" />
        <Callout
          status="error"
          text="Si è verificato un errore durante l'accesso alla piattaforma"
        >
          <Typography variant="body/sm/regular">
            Questo potrebbe essere dovuto a: provider{' '}
            <strong>
              {providerToDisplayName[props.selectedProvider]} momentaneamente
              non disponibile
            </strong>
            , <strong>credenziali errate</strong> o{' '}
            <strong>mancanza dei permessi</strong> per accedere alla piattaforma
            o al dato richiesto.
          </Typography>
        </Callout>
        <Flex justifyContent="space-between" width="100%">
          {/* The style is required due to the fact that mui adds absolute positioning for buttons inside the modal */}
          <Button
            variant="plain"
            size="lg"
            color="grey"
            onClick={props.retry}
            style={{
              position: 'relative !important',
              top: '0 !important',
              right: '0 !important',
            }}
          >
            Riprova
          </Button>
          <Button
            variant="plain"
            size="lg"
            color="brand"
            onClick={props.onLoginFailure}
            rightIcon={<Icon name="ArrowRight" />}
            style={{
              position: 'relative !important',
              top: '0 !important',
              right: '0 !important',
            }}
          >
            Accedi ad un'altra piattaforma
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
