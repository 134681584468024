import { C2SEvent, LoginStep, S2CEvent, TwoFactorAuth } from '../types';
import React, { useContext, useEffect, useState } from 'react';
import { LoginContext } from './useLogin';
import { exhaustiveCheck } from '../../_shared/utils/utils';

interface TwoStepSelectionEventsHandlerProps {
  automaticallySelected?: TwoFactorAuth;
}

export function useTwoStepSelectionEventsHandler({
  automaticallySelected,
}: TwoStepSelectionEventsHandlerProps) {
  const loginContext = useContext(LoginContext);
  if (!loginContext || !loginContext.socket)
    throw new Error(
      'useTwoStepSelectionEventsHandler must be used within a LoginContext, and socket cannot be null.',
    );

  const { socket, setStep } = loginContext;

  // Loading state for the two factor-auth methods, only 'notification' is used for now, but for generalisation we keep the other possible states
  // as of now loading is never set to 'sms' (if sms is automatically set this step is not rendered)
  const [nameOfTwoFactorInLoadingState, setNameOfTwoFactorInLoadingState] =
    useState<TwoFactorAuth | undefined>(automaticallySelected);
  const [nameOfSelectedTwoFactor, setNameOfSelectedTwoFactor] =
    React.useState<TwoFactorAuth>();
  const [selectedOtpGenerator, setSelectedOtpGenerator] =
    React.useState<string>();

  const handleRequestNotification = () => {
    setNameOfTwoFactorInLoadingState(TwoFactorAuth.NOTIFICATION);
    // Request notification for 2FA
    socket.emit(C2SEvent.NOTIFICATION);
  };

  const handleRequestSMS = () => {
    setNameOfTwoFactorInLoadingState(TwoFactorAuth.SMS);
    // Request an OTP via SMS
    socket.emit(C2SEvent.SMS_REQUEST);
  };

  const handleRequestOTP = (otpId?: string) => {
    socket.emit(C2SEvent.OTP_SELECT, otpId ? { otpId } : undefined);
    setStep(LoginStep.otpInput);
  };

  function handleChosenTwoFactorAuthMethod() {
    return () => {
      switch (nameOfSelectedTwoFactor) {
        case TwoFactorAuth.NOTIFICATION:
          handleRequestNotification();
          break;
        case TwoFactorAuth.SMS:
          handleRequestSMS();
          break;
        case TwoFactorAuth.OTP:
          handleRequestOTP(selectedOtpGenerator);
          break;
        case undefined:
          break;
        default:
          exhaustiveCheck(nameOfSelectedTwoFactor, () => {
            throw new Error(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              `Invalid TwoFactorAuth value ${nameOfSelectedTwoFactor as any}`,
            );
          });
      }
    };
  }

  useEffect(() => {
    socket.on(S2CEvent.SMS_OTP_REQUIRED, () => {
      setNameOfTwoFactorInLoadingState(undefined); // Reset loading state
      setStep(LoginStep.otpInput); // Move to OTP step
    });
    socket.on(S2CEvent.LOGIN_COMPLETED, () => {
      setNameOfTwoFactorInLoadingState(undefined); // Reset loading state
      setStep(LoginStep.authorisationAndFinalisation);
    });
    socket.on(S2CEvent.ERROR, (data: { code: string; message: string }) => {
      console.error('Error received from the server:', data);
      setStep(LoginStep.error);
      // TODO: display the correct message to the user, but i think the best way for the user is to start over in this case
    });

    // Cleanup on component unmount
    return () => {
      socket.off(S2CEvent.SMS_OTP_REQUIRED);
      socket.off(S2CEvent.LOGIN_COMPLETED);
      socket.off(S2CEvent.ERROR);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    nameOfTwoFactorInLoadingState,
    setNameOfTwoFactorInLoadingState,
    nameOfSelectedTwoFactor,
    setNameOfSelectedTwoFactor,
    selectedOtpGenerator,
    setSelectedOtpGenerator,
    handleRequestNotification,
    handleRequestSMS,
    handleRequestOTP,
    handleChosenTwoFactorAuthMethod,
  };
}
