import React, { PropsWithChildren } from 'react';
import { Platforms, platformToDisplayName } from '../../types';
import { RoundedBox } from './RoundedBox';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';

export const PlatformBox: React.FC<
  PropsWithChildren<{ platform: Platforms }>
> = ({ platform, children }) => (
  <RoundedBox index={0} total={1} style={{ position: 'relative' }}>
    <Box style={{ position: 'absolute', top: '-10px', left: '15px' }}>
      <Flex>
        <Typography
          variant="body/xs/regular"
          color="grey.11"
          style={{ background: 'white', paddingLeft: '4px' }}
        >
          Recuperemo da&nbsp;
        </Typography>
        <Typography
          variant="body/xs/semibold"
          color="grey.11"
          style={{ background: 'white', paddingRight: '4px' }}
        >
          {platformToDisplayName[platform]}
        </Typography>
      </Flex>
    </Box>
    {children}
  </RoundedBox>
);
