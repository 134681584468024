import React, { PropsWithChildren } from 'react';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';

type Props = {
  text: string;
};

const Ribbon: React.FC<PropsWithChildren<Props>> = ({ text }) => {
  return (
    <Box
      padding="sm-3"
      width="300px"
      overflow="hidden"
      style={{
        backgroundColor: '#14B62B',
        position: 'fixed',
        zIndex: 3,
        transform: 'rotate(45deg)',
        top: 50,
        right: -75,
      }}
    >
      <Typography
        variant="body/base/semibold"
        color="white"
        textAlign="center"
        textTransform="uppercase"
        style={{ overflow: 'hidden' }}
      >
        {text}
      </Typography>
    </Box>
  );
};

Ribbon.displayName = 'Ribbon';
export default Ribbon;
