import React from 'react';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { Box } from '@xtreamsrl/react-ui-kit/Box';

type Props = {
  qrImageSrc: string | undefined;
  valid: boolean;
};
const QRCodeDisplay: React.FC<Props> = ({ qrImageSrc, valid }) => {
  if (valid && qrImageSrc)
    return (
      <Flex
        flex={1}
        alignItems="center"
        justifyContent="center"
        gap="md-2"
        direction="column"
      >
        {/* TODO: this is not so beautiful!!! CHECK IT OUT */}
        <Box alignSelf="flex-start" marginLeft="xl-2">
          <Typography variant="body/xxl/semibold">Entra con l'APP</Typography>
        </Box>
        <img
          style={{ border: '1px solid grey', maxHeight: '200px' }}
          src={qrImageSrc}
          alt="QR code"
        />
        <Flex direction="column" alignItems="center">
          <Typography variant="body/base/medium">
            {/*TODO: ADD CUSTOMISATION*/}
            Hai l'applicazione di autenticazione?
          </Typography>
          <Typography
            variant="body/base/regular"
            color="grey.11"
            textAlign="center"
          >
            Inquadra il QR Code con l’App per accedere rapidamente
          </Typography>
        </Flex>
      </Flex>
    );

  if (!valid && qrImageSrc)
    return (
      <Flex flex={1} alignItems="center" justifyContent="center">
        <Typography variant="body/base/regular">
          QR code non utilizzabile
        </Typography>
      </Flex>
    );

  return null;
};

QRCodeDisplay.displayName = 'QRCodeDisplay';
export default QRCodeDisplay;
